body {
    font: 14px "Noto Sans", "Lucida Grande", Helvetica, Arial, sans-serif;
    margin: 0;
    padding-top: 30px;
    background: #323442;
    color: #aeaeae;
    text-align: center;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
}

a,
a:visited {
    color: #ed106a;
    text-decoration: none;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
}

hr {
    border: none;
    height: 1px;
    background: #555;
}

h1,
h2 {
    font-weight: normal;
}

h1 {
    display: inline;
    font-size: 2em;
    color: #fafafa;
}

h2 span {
    display: block;
    width: 100%;
    text-align: center;
}

#page-container {
    display: flex;
    min-height: calc(100vh - 2.5em /* footer height */);
    flex-direction: column;
    margin: 0 1em;
}

#content-wrap {
    flex: 1;
}

#logo {
    background: url("../common/logo.svg");
    width: 128px;
    height: 128px;
    background-size: 128px;
    margin: 0 auto 30px;
}

#status {
    width: 600px;
    margin: 30px auto;

    &::before,
    &::after {
        display: table;
        content: "";
    }

    &::after {
        clear: both;
    }

    h2 span {
        display: flex;
        align-items: center;
        justify-content: center;

        &.status {
            font-weight: bold;
        }
    }

    li {
        width: 150px;
        float: left;
        margin: 0;
        text-align: center;
    }

    .state-0 /* down */ {
        color: #dd606f;
    }

    .state-1 /* normal */ {
        color: #86ad4c;
    }

    .state-2 /* issues */ {
        color: #e8cb2d;
    }

    .state-3 /* maintenance */ {
        color: #d3d3d3
    }

    .details {
        font-size: 0.8em;
        padding: 0 0.2em;
    }
}

#feed {
    background-color: #292f33;
    border-radius: 5px;
    padding: 0.5em;
    margin: 30px auto;
    max-width: 620px;
    font-size: 0.8em;
    text-align: initial;

    a,
    a:visited {
        color: #388cce;
        text-decoration: none;
    }

    h2 {
        margin: 0;
        display: inline;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    tr {
        border: solid #555;
        border-width: 1px 0;
    }

    td {
        padding: 1em 0;
    }

    tr:first-child {
        margin-top: 0.5em;
    }

    tr:last-child {
        border-bottom: none;
    }

    .feed-text {
        color: #fafafa;
    }

    .feed-time {
        padding-top: 0.5em;
    }

    .feed-empty {
        font-size: 14px;
        text-align: center;
    }

    .header {
        margin-bottom: 0.5em;
    }
}

#footer {
    width: 100%;
    height: 2.5em;
    font-size: 14px;
    text-align: left;
}

@media only screen and (max-width: 599px) {
    #status {
        width: auto;

        li {
            float: none;
            width: auto;
            padding: 10px 0;
        }
    }

    #feed > .content {
        width: auto;
    }
}

@media only screen and (max-width: 999px) {
    #footer {
        text-align: center;
    }
}
